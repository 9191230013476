<template>

  <div style="width: 100vw;height: 100vh">
    <transition name="my">
      <img style="height: 100%;opacity: 1;" src="../../assets/loginBack.png"  v-if="show">
    </transition>
    <div style="height: 100vh;float: right;display: table;margin-right: 5%">
      <div style="height: 100vh;display: table-cell;vertical-align: middle">
        <div style="background: #F9DFCD" class="card">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="beiAn">
      <bei-an></bei-an>
    </div>
  </div>

</template>

<script>
import BeiAn from '../../layouts/beiAn/beiAn'
export default {
  name: "layout",
  data(){
    return{
      show:true
    }
  },
  mounted() {
    this.show = true;
  },
  components:{
    BeiAn
  }
}
</script>

<style scoped>
.card{
  border-radius: 20px;
  padding: 80px 50px;
  width: 500px;
  box-sizing: border-box;
  overflow: hidden;

}
.my-enter,.my-leave-to{
  opacity: 0;
  transform: translateX(80px);
}
/*进入和离开的动画时间段*/
.my-enter-active,.my-leave-active{
  transition: all 0.5S ease
}

.beiAn{
  width: 100vw;
  position: absolute;
  bottom: 0;
  /*height: 100px;*/
  z-index: 9999;
}

</style>